const types = {
  403: {
    title: '403',
    desc: 'Sorry, you do not have permission to access this page'
  },
  404: {
    title: '404',
    desc: 'Sorry, the page you visited does not exist or is still under construction'
  },
  500: {
    title: '500',
    desc: 'Sorry, server error'
  }
}

export default types
