<template>
  <div class="exception">
    <div class="content">
      <h1>{{ config[type].title }}</h1>
      <h2 class="desc">{{ config[type].desc }}</h2>
      <div class="actions">
        <a-button type="primary" @click="handleToHome">{{ $t('basics.go_back') }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import types from './type'

export default {
  name: 'Exception',
  props: {
    type: {
      type: String,
      default: '404'
    }
  },
  data () {
    return {
      config: types
    }
  },
  methods: {
    handleToHome () {
      this.$router.push({ name: '/' })
    }
  }
}
</script>
